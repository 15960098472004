@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.table-wrapper {
  overflow-y: auto;
}

table {
  width: 100%;
  min-width: 400px;
  border-spacing: 0;
}

thead {
  background-color: var(--second-bg);
}

tr {
  text-align: left;
}

th,
td {
  padding: 15px 10px;
}
td img {
  width: 60px;
}

tbody > tr:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
}

.table__pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.table__pagination-item ~ .table__pagination-item {
  margin-left: 10px;
}

.table__pagination-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table__pagination-item.active,
.table__pagination-item.active:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
  font-weight: 600;
}

.table__pagination-item:hover {
  color: var(--txt-white);
  background-color: var(--second-color);
}

.table_btn {
  width: 30px;
  height: 30px;
  background: #fff;
  display: inline-grid;
}
.table_btn img {
  width: 60%;
}
tbody > tr:hover .table_btn {
  background: var(--main-color);
}
tbody > tr:hover .table_btn img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.switch {
  display: flex;
  font-size: 16px;
  margin-left: auto;
  margin-right: 30px;
}
.toggle {
  align-items: center;
  border-radius: 100px;
  display: flex;
  font-weight: 700;
  margin-bottom: 16px;
}
.toggle:last-of-type {
  margin: 0;
}
.lang {
  padding: 0 10px;
  text-transform: uppercase;
}
.toggle__input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.toggle__input:not([disabled]):active + .toggle-track,
.toggle__input:not([disabled]):focus + .toggle-track {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.3);
}
.toggle__input:disabled + .toggle-track {
  cursor: not-allowed;
  opacity: 0.7;
}
.toggle-track {
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  height: 30px;
  position: relative;
  width: 60px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.3);
}
.toggle-indicator {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  bottom: 2px;
  display: flex;
  height: 24px;
  justify-content: center;
  left: 2px;
  outline: solid 2px transparent;
  position: absolute;
  transition: 0.5s all ease;
  width: 24px;
}
.checkMark {
  fill: #fff;
  height: 20px;
  width: 20px;
  opacity: 0;
  transition: opacity 0.5s all ease-in-out;
}
.toggle__input:checked + .toggle-track .toggle-indicator {
  background: rgba(0, 0, 0, 0.5);
  transform: translateX(30px);
}
.toggle__input:checked + .toggle-track .toggle-indicator .checkMark {
  opacity: 1;
  transition: opacity 0.5s all ease-in-out;
}
@media screen and (-ms-high-contrast: active) {
  .toggle-track {
    border-radius: 0;
  }
}

.sidebar {
  min-width: 300px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.sidebar__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__logo > img {
  height: 45px;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.sidebar__item {
  padding: 0 20px;
}

.sidebar__item-inner {
  color: #000;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s ease 0s;
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: #349eff;
}

.sidebar__item-inner.active {
  border-radius: 15px;
  background-image: linear-gradient(
      to right,
      #349eff,
      #62b4ff
  );
  color: #fff;
}

.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

[class*="col-"] {
  padding: 0 15px;
}

.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

/* MEDIUM SCREEN */

@media only screen and (max-width: 1280px) {
  .col-md-1 {
      width: 8.33%;
  }

  .col-md-2 {
      width: 16.66%;
  }

  .col-md-3 {
      width: 25%;
  }

  .col-md-4 {
      width: 33.33%;
  }

  .col-md-5 {
      width: 41.66%;
  }

  .col-md-6 {
      width: 50%;
  }

  .col-md-7 {
      width: 58.33%;
  }

  .col-md-8 {
      width: 66.66%;
  }

  .col-md-9 {
      width: 75%;
  }

  .col-md-10 {
      width: 83.33%;
  }

  .col-md-11 {
      width: 91.66%;
  }

  .col-md-12 {
      width: 100%;
  }
}

/* SMALL SCREEN */

@media only screen and (max-width: 500px) {
  .col-sm-1 {
      width: 8.33%;
  }

  .col-sm-2 {
      width: 16.66%;
  }

  .col-sm-3 {
      width: 25%;
  }

  .col-sm-4 {
      width: 33.33%;
  }

  .col-sm-5 {
      width: 41.66%;
  }

  .col-sm-6 {
      width: 50%;
  }

  .col-sm-7 {
      width: 58.33%;
  }

  .col-sm-8 {
      width: 66.66%;
  }

  .col-sm-9 {
      width: 75%;
  }

  .col-sm-10 {
      width: 83.33%;
  }

  .col-sm-11 {
      width: 91.66%;
  }

  .col-sm-12 {
      width: 100%;
  }
}
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: #349eff;
  color: var(--main-color);
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: #455560;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid #349eff;
  border: 2px solid var(--main-color);
}

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: #ffffff;
  background-color: var(--main-bg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: #ffffff;
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: #2d2d2d;
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: #349eff;
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: #fb0b12;
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: #10d4d2;
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: #019707;
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: #d68102;
  background-color: var(--main-color-orange);
  color: #fff;
}

.login {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.login-title {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}
.form {
  width: 40%;
  padding: 80px;
}
.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  width: 100%;
}
.input-group label {
  position: absolute;
  cursor: text;
  font-size: 80%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  color: rgba(58, 58, 58, 0.7);
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 12px;
}
.input-group img {
  width: 100%;
  border: 2px solid #000;
}
.input-group label::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
  background: #fff;
}

.input-group input {
  display: block;
  width: 100%;
  font-weight: 400;
  border-radius: 0.1rem;
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  border: 1px solid #d7d7d7;
  background: white;
  color: #3a3a3a;
  height: auto;
  height: initial;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-button {
  width: 180px;
  margin-left: auto;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #73236b;
  border-color: #73236b;
  border-radius: 50px;
}
.login-button:hover,
.login-button:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.add-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background: linear-gradient(to right, #349eff, #62b4ff);
  border-radius: 20px;
}
.add-btn:hover {
  color: #349eff;
  background: #fff;
  border-color: #349eff;
}

.crud-page {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  background: var(--main-bg);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
  box-shadow: var(--box-shadow-dark);
  padding: 30px;
}

.crud-page .form {
  width: 100%;
  padding: 0;
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.crud-page .form .input-group {
  width: 48%;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  position: relative;
}
.btns button,
.btns a {
  width: 100px;
  height: 40px;
  font-weight: 400;
  color: #212529;
  text-align: center;
  margin-left: unset;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  border-radius: 10px;
}
.close-btn {
  margin-right: 20px;
  background-color: #2d2d2d;
  background-color: var(--main-bg-dark);
}

.img-group {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.img-group img {
  width: 100%;
}

