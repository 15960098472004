.sidebar {
  min-width: 300px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.sidebar__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__logo > img {
  height: 45px;
  filter: invert(1);
}

.sidebar__item {
  padding: 0 20px;
}

.sidebar__item-inner {
  color: #000;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s ease 0s;
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: #349eff;
}

.sidebar__item-inner.active {
  border-radius: 15px;
  background-image: linear-gradient(
      to right,
      #349eff,
      #62b4ff
  );
  color: #fff;
}