.table-wrapper {
  overflow-y: auto;
}

table {
  width: 100%;
  min-width: 400px;
  border-spacing: 0;
}

thead {
  background-color: var(--second-bg);
}

tr {
  text-align: left;
}

th,
td {
  padding: 15px 10px;
}
td img {
  width: 60px;
}

tbody > tr:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
}

.table__pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.table__pagination-item ~ .table__pagination-item {
  margin-left: 10px;
}

.table__pagination-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table__pagination-item.active,
.table__pagination-item.active:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
  font-weight: 600;
}

.table__pagination-item:hover {
  color: var(--txt-white);
  background-color: var(--second-color);
}

.table_btn {
  width: 30px;
  height: 30px;
  background: #fff;
  display: inline-grid;
}
.table_btn img {
  width: 60%;
}
tbody > tr:hover .table_btn {
  background: var(--main-color);
}
tbody > tr:hover .table_btn img {
  filter: invert(1);
}
